import * as React from "react";

import useMediaQuery from "@material-ui/core/useMediaQuery";

import Grid from "@material-ui/core/Grid";
import { Layout } from "../../components/Layout";
import { CardMedia } from "@material-ui/core";
import { Slide } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import Seo from "../../components/Seo/Seo";
import { CallbackBanner } from "../../components/CallbackBanner";

import DeepCleanImg from "../../images/service-grid/people_cleaning_room.jpeg";

import { useStyles } from "../../pagesStyles/aboutUs.styling";

const DeepCleaning = () => {
  const classes = useStyles();
  const desktop = useMediaQuery("(min-width:769px)");

  const breadcrumbs = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "Services",
      link: "/services",
    },
    {
      name: "Deep Cleaning",
    },
  ];

  return (
    <Layout gutter marginTop breadcrumb={breadcrumbs}>
      <Seo title="Deep Cleaning" />
      <section style={{ padding: desktop ? 0: "0 5px"  }} spacing={5}>
        <Grid container direction={desktop ? "row" : "column"}>
          <Grid item xs={desktop ? 6 : 12}>
            <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
              <strong>Deep Cleaning </strong>
            </Typography>
            <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
            Are you looking for a company to help with a particularly big clean up? 
            Sometimes trying to tackle a big cleaning job is 
            just not possible on your own. Why not hire us to help take the stress away?
            </Typography>
            <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
            Whether it is a rental property that has come to the end of the rental agreement or 
            a property that’s due to be rented out or put up for sale and is in need of a complete deep clean or whether 
            it’s a clean up after a party that has left stains on carpets and debris everywhere.
            </Typography>
            <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
            PCH Services will ensure that your property is left fresh and sparkling again in no time.
            </Typography>
          </Grid>
          <Grid item xs={desktop ? 6 : 12}>
            <Slide direction="right" in timeout={700}>
              <CardMedia
                style={{ height: "60vh" }}
                image={DeepCleanImg}
                // alt={altTag}
                classes={{ root: classes.cardImageL }}
                component="img"
              />
            </Slide>
          </Grid>
        </Grid>
      </section>
      <CallbackBanner/>
    </Layout>
  );
};

export default DeepCleaning;
